import React from 'react'
import { Layout, SEO } from '../components'
import { Container, Row, Col } from 'react-bootstrap'

const block = 'huis-menu'

// Query for the Blog Home content in Prismic
export const query = graphql`
  query MenuQuery {
    prismicMenu {
      data {
        combo_section_title
        build_your_own_section_title
        sizes {
          size_and_price
        }
        combos {
          colours
          description
          title
        }
        flavours {
          title
          colour
        }
        add_ons {
          add_on
        }
      }
    }
  }
`

const parseMenuData = ({ data }) => {
  const comboSectionTitle = (data && data.combo_section_title) || ''
  const sizes = (data && data.sizes) || []
  const combos = (data && data.combos) || []
  const buildYourOwnSectionTitle = (data && data.combo_section_title) || ''
  const flavours = (data && data.flavours) || []
  const addOns = (data && data.add_ons) || []

  return {
    comboSectionTitle,
    combos,
    buildYourOwnSectionTitle,
    flavours,
    sizes,
    addOns,
  }
}

const Menu = ({ data }) => {
  const menu = (data && data.prismicMenu) || {}
  const {
    sizes,
    comboSectionTitle,
    combos,
    buildYourOwnSectionTitle,
    flavours,
    addOns,
  } = parseMenuData(menu)

  return (
    <Layout pageInfo={{ pageName: 'menu' }}>
      <SEO title="Menu | Huis Shave Ice" />
      <Container>
        <Row>
          <Col
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            className="text-center mt-4 mb-md-3"
          >
            <h1> Menu </h1>
          </Col>
        </Row>

        <Row>
          <Col
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            className="mt-4 mt-md-0 mb-5"
          >
            <div className={`${block}__menu-board`}>
              <Col xs={12} className={`${block}__section-title`}>
                Sizes
              </Col>
              <div xs={12} className={`${block}__sizes-list`}>
                {sizes.map(({ size_and_price }, index) => {
                  return (
                    <Col xs={12} className={`${block}__sizes`} key={index}>
                      {size_and_price}
                    </Col>
                  )
                })}
              </div>

              <Col xs={12} className={`${block}__section-title`}>
                {comboSectionTitle}
              </Col>
              <div className={`${block}__combo-list`}>
                {combos.map(({ title, description, colours = [] }) => {
                  let linearGradient =
                    (colours.length &&
                      colours
                        .split(',')
                        .map((color) => `#${color}`)
                        .join(', ')) ||
                    '#353535, #353535'
                  const style = {
                    background: `linear-gradient(to bottom, ${linearGradient})`,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }
                  return (
                    <Col
                      xs={{ span: 12 }}
                      md={{ span: 6, offset: 0 }}
                      lg={{ span: 4, offset: 0 }}
                      className={`${block}__combo-container`}
                      key={title}
                    >
                      <div
                        data-text={title}
                        className={`${block}__combo-title`}
                        style={style}
                      >
                        {title}
                      </div>
                      <div className={`${block}__combo-description`}>
                        {description}
                      </div>
                    </Col>
                  )
                })}
              </div>

              <Col xs={12} className={`${block}__section-title`}>
                {buildYourOwnSectionTitle}
              </Col>
              <div xs={12} className={`${block}__flavor-list`}>
                {flavours.map(({ title, colour = '' }) => {
                  const style = colour
                    ? { color: `#${colour}` }
                    : { color: '#fff' }

                  return (
                    <Col
                      xs={6}
                      sm={6}
                      md={4}
                      key={title}
                      className={`${block}__flavor-wrapper`}
                    >
                      <div style={style} className={`${block}__flavor-title`}>
                        {title}
                      </div>
                    </Col>
                  )
                })}
              </div>

              {addOns && addOns.length > 0 && (
                <>
                  <Col xs={12} className={`${block}__section-title`}>
                    Add-Ons
                  </Col>
                  <div className={`${block}__add-on-list`}>
                    {addOns.map(({ add_on }, index) => {
                      return (
                        <Col
                          xs={12}
                          className={`${block}__add-on-item`}
                          key={index}
                        >
                          {add_on}
                        </Col>
                      )
                    })}
                  </div>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Menu
